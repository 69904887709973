<template>
    <main id="liste" v-cloak>
        <HeaderTab :title="$t('invoice.export_deb_des')" :swiper_tabs="swiper_tabs" />

        <div id="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div>
                            <router-view></router-view>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>
<script type="text/javascript">

	export default {
		name: 'ExportDEBDES',
		mixins: [],
		data () {
			return {
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {

			},
		},

		computed: {
            swiper_tabs() {
                return [{
                        id: 'export_des',
                        label: 'invoice.export_des',
                        active: false,
                        href : 'exportDES',
                    }, {
                        id: 'export_deb',
                        label: 'invoice.export_deb',
                        active: false,
                        href : 'exportDEB'
                    }]
            }
        },

		components: {
            HeaderTab: () => import('@/components/HeaderTab'),
		}
	}

</script>